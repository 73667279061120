<template>
  <v-dialog
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
    v-model="dialog"
  >
    <template v-slot:activator="{ on }">
      <div>
        <v-tooltip bottom v-if="appointment.depositPayed">
          <template v-slot:activator="{ on }">
            <v-btn small icon v-on="on">
              <v-icon small color="success">mdi-check</v-icon>
            </v-btn>
          </template>
          {{ $t("payed") }}
        </v-tooltip>
        <v-btn icon style="height: 30px" small v-on="on" v-else>
          <v-icon small> $edit</v-icon>
        </v-btn>

        {{ $n(appointment.deposit, "currency") }}

        <template v-if="!appointment.depositPayed">
          <v-btn small outlined class="mr-1" @click="action('return')">
            Devolver
          </v-btn>
          <v-btn small outlined @click="action('liquidate')">Liquidar</v-btn>
        </template>
      </div>
    </template>

    <v-card>
      <v-card-title>{{ $t("update_deposit") }} </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="deposit-form"
        @submit.prevent="validateForm('deposit-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col cols="8" class="py-0">
              <label>{{ $t("expenses.pay_mode") }}</label>
              <v-select
                outlined
                dense
                hide-details
                v-model="formData.method"
                :items="methods"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('deposit-form.method')"
                data-vv-name="method"
              ></v-select>
            </v-col>
            <v-col cols="8" class="py-0">
              <label>{{ $t("quantity") }}</label>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="formData.quantity"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('deposit-form.quantity')"
                data-vv-name="quantity"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            @click="accion = 'save'"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>

          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            @click="accion = 'send'"
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("enviar", { name: $t("") }) }}
          </v-btn>
          <SendMail ref="sendMail" hidden :appointment="appointment" />
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditDeposit",
  props: ["appointment"],
  components: { SendMail: () => import("@/components/mails/SendMail") },
  data() {
    return {
      dialog: false,
      formData: {},
      accion: "send",
      methods: [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ],
    };
  },
  mounted() {
    this.formData = {
      quantity: this.appointment.deposit,
    };
  },
  methods: {
    ...mapActions("appointments", ["actionDeposit", "editDeposit"]),
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log("VALIDADO");
        this.editDeposit({
          appointment_id: this.appointment.id,
          formData: this.formData,
        }).then((response) => {
          this.$emit("update");
          if (this.accion == "send") this.$refs.sendMail.open("DEPÓSITO");
        });
      }
    },
    action(action) {
      this.$confirm(this.$t("deposit." + action)).then(() => {
        this.actionDeposit({
          appointment_id: this.appointment.id,
          formData: null,
          action,
        }).then(() => {
          this.dialog = false;
          this.$emit("update");
        });
      });
    },
  },
};
</script>

<style lang="sass" scoped>
label
  line-height: 1
</style>
